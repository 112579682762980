import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { TableHead } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import axios from 'axios';

const styles = theme => ({
    formControl: {
      margin: theme.spacing(1),
      width: 500,
    },
    containerDiv: {
        display: 'inline'
    },
    sourceSide: {
        float: 'left'
    },
    destinationSide: {
        float: 'right'
    },
    submitButton: {
        position: 'fixed',
        bottom: '20px',
        right: '20px'
    },
    list: {
        width: 500,
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
      },
    heading: {
        'margin-left': '40%',
        'margin-top': '-45px',
        'margin-bottom': '30px'
    },
    root: {
        width: "100vw",
        marginTop: theme.spacing.unit * 3,
      },
      table: {
        minWidth: 600,
      },
      tableWrapper: {
        overflowX: "auto",
      },
      tableHeader: {
        fontSize: "15px",
        fontWeight: "bold",
        align: "left",
      },
      wideColumnHeader: {
        fontSize: '15px',
        fontWeight: "bold",
      },
      info: {
        width: "5%",
      },
      time: {
        width: "10%",
        align: 'left'
      },
      agent: {
        width: "18%",
        align: 'left'
      },
      department: {
        width: "10%",
        align: 'left'
      },
      type:{
        width: "7%",
        align: 'left'
      },
      number: {
        width: "10%",
        align: 'left'
      },
      duration: {
        width: "9%",
        align: 'left'
      },
      uri: {
        width: "auto",
        align: 'left'
      },
      pagination: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing.unit * 2.5,
      }
  });

export class HistoryComponent extends React.Component{
    constructor(props){
        super();
        this.state = {
            isLoading: false,
            callLogs: [],
            page: 0,
            rowsPerPage: 20,
        }
    }
    componentDidMount(){
        this.fetchHistory();
    }
    fetchHistory = async (event) => {
        this.setState({ isLoading: true });
        let call_history = await axios({
            method: 'get',
            url: '/history',
            params: {limit: this.state.rowsPerPage+1, offset: this.state.page*this.state.rowsPerPage}
        })
        await this.setState({
            isLoaded: false,
            callLogs: call_history.data.data
        });
    }    

    handleChangePage = async (event, page) => {
      // const _page = event.target.value;
      console.log('>>>>>>> handle change page', event, page);
      // this.props.setPage(page);
      await this.setState({ page: page });
      this.fetchHistory();
    };
  
    handleChangeRowsPerPage = async (event) => {
      const rowsPerPage = parseInt(event.target.value);
      await this.setState({ page: 0 });
      await this.setState({ rowsPerPage: rowsPerPage });
      this.fetchHistory();

      // this.props.getCallsData({isDefault: this.props.isDefault}).then(() => {this.setState({ page: 0, rowsPerPage: rowsPerPage });});
    };

    handleFirstPageButtonClick = async (event) => {
      // await this.setState({ page: 0 });
      this.handleChangePage(event, 0);
    };
  
    handleBackButtonClick = (event) => {
      // await this.setState({ page: this.state.page -1 });
      this.handleChangePage(event, this.state.page - 1);
    };
  
    handleNextButtonClick = (event) => {
      this.handleChangePage(event, this.state.page + 1);
    };
  
    handleLastPageButtonClick = (event) => {
      this.handleChangePage(
        event,
        Math.max(0, Math.ceil(this.state.callLogs.length / this.state.rowsPerPage) - 1)
      );
    };

    render() {
        const { rowsPerPage, page } = this.state;
        let counter = 1;
        let classes = this.props.classes;
        let rows = this.state.callLogs || [];
        console.log("rows >>>>>>", rows);
        return (
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {/* <TableCell className={[classes.tableHeader, classes.info].join(' ')}>Info</TableCell> */}
                    <TableCell align="left" className={[classes.tableHeader, classes.time].join(' ')}>
                        Logged At
                    </TableCell>
                    <TableCell align="left" className={[classes.tableHeader, classes.department].join(' ')}>
                        User ID
                    </TableCell>
                    <TableCell align="left" className={[classes.tableHeader, classes.department].join(' ')}>
                        Username
                    </TableCell>
                    <TableCell align="left" className={[classes.tableHeader, classes.department].join(' ')}>
                        Component
                    </TableCell>
                    <TableCell align="left" className={[classes.tableHeader, classes.department].join(' ')}>
                        Source
                    </TableCell>
                    <TableCell align="left" className={[classes.tableHeader, classes.department].join(' ')}>
                        Destination
                    </TableCell>
                    <TableCell align="left" className={[classes.tableHeader, classes.agent].join(' ')}>
                        Copied content
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                      return (
                        <TableRow key={counter++}>
                          {/* <TableCell>
                            <InfoIcon
                              onClick={() => this.props.showTaskInfo(row.task_info)}
                            ></InfoIcon>
                          </TableCell> */}
                          <TableCell component="th" scope="row">
                            {row.created_at}
                          </TableCell>
                          <TableCell align="left" className={classes.wideColumnCell}>{row.user_id}</TableCell>
                          <TableCell align="left">{row.user_name}</TableCell>
                          <TableCell align="left">{row.component}</TableCell>
                          <TableCell align="left">{row.source}</TableCell>
                          <TableCell align="left">{row.destination}</TableCell>
                          <TableCell align="left">{row.content}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20, 50]}
                      colSpan={3}
                      count={this.state.callLogs.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        native: true,
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      icons={{
                        NextPage: () => {
                          return <div className={classes.pagination}><IconButton
                          onClick={this.handleNextButtonClick}
                          disabled={page === 0}
                          aria-label="Next Page"
                        ><KeyboardArrowRight /></IconButton></div>;
                        },
                        PreviousPage: () => {
                          return <div className={classes.pagination}><IconButton
                          // onClick={this.handleNextButtonClick}
                          disabled={page === 0}
                          aria-label="Previous Page"
                        ><KeyboardArrowLeft /></IconButton></div>;
                        },
                        FirstPage: () => {
                          return <div className={classes.pagination}><IconButton
                                  onClick={this.handleFirstPageButtonClick}
                                  disabled={page === 0}
                                  aria-label="First Page"
                                ><FirstPageIcon />
                                </IconButton></div>;
                        }
                        // LastPage: () => {
                        //   return <div className={classes.pagination}><IconButton
                        //           onClick={this.handleLastPageButtonClick}
                        //           disabled={this.state.page >= Math.ceil(this.state.callLogs.length / this.state.rowsPerPage) - 1}
                        //           aria-label="Last Page"
                        //         ><LastPageIcon />
                        //         </IconButton></div>;
                        // }
                      }}
                      // ActionsComponent={TablePaginationActionsWrapped}
                      // ActionsComponent={(subProps) => (
                      //   <TablePaginationActionsWrapped
                      //     {...subProps}
                      //     handlers={this.props}
                      //   />
                      // )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </Paper>
        );
      }
}

export default (withStyles(styles)(HistoryComponent));