import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TablePaginationActionsWrapped from "./Paginatation";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const styles = (theme) => ({
  formControl: {
    margin: "8px 0px",
    width: 500
  },
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3,
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  table: {
    minWidth: 500,
    "& td": {
      fontSize: 14
    },
    "& th": {
      fontSize: 16,
      fontWeight: "bolder"
    }
  },
  tableWrapper: {
    overflowX: "auto"
  },
  backupContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 2px"
  },
  button: {
    backgroundColor: "#1a8f79",
    color: "white",
    marginLeft: 10,
    fontWeight: "bolder"
  },
  heading: {
    fontSize: "16px",
    fontWeight: "bolder",
    padding: "10px 0px",
    color: "#005041"
  },
  toasters: {
    fontSize: 14
  }
});

export class BackupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      environments_list: [
        { name: "Dr Smile Staging", sid: "1", value: "DRSMILE_STAGING" },
        { name: "Smava Staging", sid: "2", value: "SMAVA_STAGING" },
        { name: "Localsearch Staging", sid: "3", value: "LOCALSEARCH_STAGING" },
        { name: "K24 Staging", sid: "4", value: "K24_STAGING" },
        { name: "Illy Cafe Staging", sid: "5", value: "ILLY_CAFE_STAGING" },
        { name: "Interhyp Staging", sid: "6", value: "INTERHYP_STAGING" },
        { name: "Movinga Staging", sid: "7", value: "MOVINGA_STAGING" },
        { name: "Positec Staging", sid: "8", value: "POSITEC_STAGING" },
        { name: "Experimental 2 C20Y", sid: "9", value: "EXPERIMENTAL_2_C20Y" },
        { name: "Mymoria Staging", sid: "10", value: "MYMORIA_STAGING" },
        { name: "IU Development", sid: "11", value: "IU_DEVELOPMENT" },
        { name: "IU UAT", sid: "12", value: "IU_UAT" },
        { name: "Flex Template 2.0", sid: "13", value: "FLEX_TEMPLATE_2.0" },
        { name: "Wattfox Dev", sid: "14", value: "WATTFOX_DEV" },
        { name: "Fairfax Dev", sid: "15", value: "FAIRFAX_DEV" },
        { name: "Localsearch Production", sid: "16", value: "LOCALSEARCH_PRODUCTION" },
        { name: "Localsearch Dev v2", sid: "17", value: "LOCALSEARCH_STAGING_V2" },
        { name: "ThinkProject-Main", sid: "18", value: "THINKPROJECT_MAIN" },
        { name: "ThinkProject-Stage", sid: "19", value: "THINKPROJECT_STAGE" },
        { name: "ThinkProject-Prod", sid: "20", value: "THINKPROJECT_PROD" }
      ],
      destination_list: [
        { name: "Dr Smile Production", sid: "1", value: "DRSMILE_PRODUCTION" },
        { name: "Smava Staging", sid: "2", value: "SMAVA_STAGING" },
        { name: "Localsearch Production", sid: "3", value: "LOCALSEARCH_PRODUCTION" },
        { name: "K24 Production", sid: "4", value: "K24_PRODUCTION" },
        { name: "Illy Cafe Staging", sid: "5", value: "ILLY_CAFE_STAGING" },
        { name: "Interhyp Staging", sid: "6", value: "INTERHYP_STAGING" },
        { name: "Movinga Production", sid: "7", value: "MOVINGA_PRODUCTION" },
        { name: "Positec Production", sid: "8", value: "POSITEC_PRODUCTION" },
        { name: "Experimental 2 C20Y", sid: "9", value: "EXPERIMENTAL_2_C20Y" },
        { name: "Mymoria Production", sid: "10", value: "MYMORIA_PRODUCTION" },
        { name: "IU UAT", sid: "11", value: "IU_UAT" },
        { name: "IU Production", sid: "12", value: "IU_PRODUCTION" },
        { name: "SC Ukraine Flex", sid: "13", value: "SC_UKRAINE_FLEX" },
        { name: "Wattfox Prod", sid: "14", value: "WATTFOX_PROD" },
        { name: "Fairfax Prod", sid: "15", value: "FAIRFAX_PROD" },
        { name: "Localsearch Prod v2", sid: "16", value: "LOCALSEARCH_PRODUCTION_V2" },
        { name: "Localsearch Dev v2", sid: "17", value: "LOCALSEARCH_STAGING_V2" },
        { name: "ThinkProject-Main", sid: "18", value: "THINKPROJECT_MAIN" },
        { name: "ThinkProject-Stage", sid: "19", value: "THINKPROJECT_STAGE" },
        { name: "ThinkProject-Prod", sid: "20", value: "THINKPROJECT_PROD" }
      ],
      rows: [],
      page: 0,
      rowsPerPage: 10
    };
  }

  componentDidMount() {}

  sourceSelected = (event) => {
    this.setState(
      {
        source: event.target.value
      },
      () => {
        this.getVersionHistory();
      }
    );
  };

  getVersionHistory = () => {
    this.props.toggleLoader();
    const { page, rowsPerPage } = this.state;

    const { classes } = this.props;
    axios({
      method: "post",
      url: "/version",
      data: {
        sourceKey: this.state.source,
        size: rowsPerPage,
        from: page * rowsPerPage
      },
      headers: { "Content-Type": "Application/json" }
    })
      .then((resp) => {
        if (resp && resp.data && resp.data.status_code == 200) {
          const data = resp.data.data;

          const rows = data.rows;
          const totalRecords = data.totalRecords;
          this.setState({
            rows,
            totalRecords
          });
        }
      })
      .catch((e) => {
        console.error("error/version", e);
        toast.error("Something went wrong while fetching version histories. Please try again later", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
          icon: "🤯",
          className: classes.toasters
        });
        console.error(e);
      })
      .finally(() => {
        this.props.toggleLoader();
      });
  };

  restore = (id) => {
    this.props.toggleLoader();

    const { classes } = this.props;

    axios({
      method: "post",
      url: "/restore",
      data: {
        id: id,
        key: this.state.source
      },
      headers: { "Content-Type": "Application/json" }
    })
      .then((resp) => {
        console.log("resp>>>", resp);
        if (resp?.data?.error == true) {
          toast.error(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
            icon: "🤯",
            className: classes.toasters
          });
        } else if (resp && resp.data && resp.data.status_code == 200) {
          toast.success("Task router has been restored to v" + id, {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
            icon: "🚀",
            className: classes.toasters
          });
        }
      })
      .catch((e) => {
        console.error("error/restore", e);
        toast.error("Sometg went wrong! Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
          icon: "🤯",
          className: classes.toasters
        });
      })
      .finally(() => {
        this.props.toggleLoader();
      });
  };

  submitForm = () => {
    const { classes } = this.props;
    this.props.toggleLoader();
    axios({
      method: "post",
      url: "/backup",
      data: {
        sourceKey: this.state.source
      },
      headers: { "Content-Type": "Application/json" }
    })
      .then((resp) => {
        if (resp && resp.data && resp.data.status_code == 200) {
          toast.success("Backup has completed", {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
            icon: "🚀",
            className: classes.toasters
          });
        }
      })
      .catch((e) => {
        console.log("error/backup", e.message);
        toast.error("Something went wrong! Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
          icon: "🤯",
          className: classes.toasters
        });
        console.error(e);
      })
      .finally(() => {
        this.props.toggleLoader();

        setTimeout(() => {
          this.setState(
            {
              page: 0
            },
            () => {
              this.getVersionHistory();
            }
          );
        }, 100);
      });
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      this.getVersionHistory();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: event.target.value
      },
      () => {
        this.getVersionHistory();
      }
    );
  };

  render() {
    let classes = this.props.classes;
    const twilio_envs_list = this.state.environments_list.map((env) => {
      return (
        <option key={env.key} value={env.key}>
          {env.name}
        </option>
      );
    });
    let destination_envs_list = this.state.destination_list.map((env) => {
      return (
        <option key={env.key} value={env.key}>
          {env.name}
        </option>
      );
    });

    const { rows, rowsPerPage, page, totalRecords, source } = this.state;
    const emptyRows = 0;
    // rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <div className={classes.root}>
        <ToastContainer />
        <div className={classes.backupContainer}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-native-select">Source</InputLabel>
            <Select native defaultValue="" id="grouped-native-select-source" onChange={this.sourceSelected}>
              <option aria-label="None" value="" />
              <optgroup label="Staging">{twilio_envs_list}</optgroup>
              <optgroup label="Production"></optgroup>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={(e) => {
              this.submitForm(e);
            }}
            disabled={!!!source}
            className={classes.button}
          >
            Take Backup
          </Button>
        </div>

        {source && (
          <>
            <div className={classes.heading}>Revision History for "{source}"</div>

            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Version</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Backup Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{"v" + row.id}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{moment(row.created_at).format("LLL")}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#1a8f79",
                          marginLeft: 0
                        }}
                        onClick={(e) => {
                          this.restore(row.id);
                        }}
                        className={classes.button}
                      >
                        Restore
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={4}
                    count={totalRecords || rows.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(BackupComponent);
