import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import ActivitiesComponent from './ActivitiesComponent';
import BackupComponent from './BackupComponent';
import TaskqueuesComponent from './TaskqueuesComponent';
import WorkflowsComponent from './WorkflowsComponent';
import FlowComponent from './FlowComponent';
import HistoryComponent from './HistoryComponent';

import CircularProgress from '@material-ui/core/CircularProgress';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#005041'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sideDrawerText: {
    fontSize: '1.5rem'
  },
  selectedTab: {
    backgroundColor: 'grey',
    background: 'grey',
    fontSize: '1.5rem'
  },
  progress: {
    width: '60px',
    height: '60px',
    position: 'absolute',
    left: 'calc(50% - 30px)',
    top: 'calc(50% - 30px)',
    color: '#005041'
  },
});

export class MainComponent extends React.Component {

  constructor(props) {
    super();
    this.state = {
      screen_size: window.visualViewport.width < 1192 ? 'mobile' : 'desktop',
      tabValue: '',
      loader: false
    }
  }

  handleClick = (tab) => {
    console.log(tab)
    if (tab === 'Activities') {
      this.setState({ tabValue: 'activities' });
    } else if (tab === 'Taskqueues') {
      this.setState({ tabValue: 'taskqueues' });
    } else if (tab === 'Workflows') {
      this.setState({ tabValue: 'workflows' });
    } else if(tab === 'History') {
      this.setState({tabValue: 'history'});
    } else if (tab === "Backup Service") {
    } else if (tab === "Backup & Restore") {
      this.setState({ tabValue: "backup-service" })
    } else if(tab === 'Flows'){
      this.setState({tabValue: 'flows'});
    }
  }

  toggleLoader = () => {
    this.setState({
      loader: !this.state.loader
    })
  }

  render() {
    let classes = this.props.classes;
    const { loader } = this.state;

    return (
      <>
        {
          (loader) && <div style={{
            position: 'absolute',
            height: '100vh',
            width: '100%',
            zIndex: 9999,
            background: 'rgba(0,0,0,.5)',
            top: 0
          }}>
            <CircularProgress className={classes.progress} size="80" />
          </div>
        }

        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h3" noWrap>
                C20Y Jarvis
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Toolbar />
            <div className={classes.drawerContainer}>
              <List>
                {['Activities', 'Taskqueues', 'Workflows', 'History', 'Backup & Restore', 'Flows'].map((text, index) => (
                  <ListItem button key={text} style={{ backgroundColor: this.state.tabValue == text.toLowerCase() ? "#948f8fc9" : "" }} onClick={() => this.handleClick(text)}>
                    <ListItemText classes={{ primary: classes.sideDrawerText }} primary={text} />
                  </ListItem>
                ))}
              </List>
              <Divider />
            </div >
          </Drawer>
          {
            this.state.tabValue !== 'backup-service' &&
              <main className={classes.content}>
              <Toolbar />
                  {(this.state.tabValue === 'activities') && <ActivitiesComponent></ActivitiesComponent>}
                  {(this.state.tabValue === 'taskqueues') && <TaskqueuesComponent/>}
                  {(this.state.tabValue === 'workflows') && <WorkflowsComponent/>}
                  {(this.state.tabValue === 'flows') && <FlowComponent/>}
                  {(this.state.tabValue === 'history') && <HistoryComponent/>}
            </main>
        }
        <main className={classes.content}>
          <Toolbar />
          {(this.state.tabValue === 'backup-service') && <BackupComponent toggleLoader={this.toggleLoader}></BackupComponent>}
        </main>
        </div>
      </>
    );
  }
}

export default (withStyles(styles)(MainComponent));